import styles from "./Services.module.css"
import cleaning from "../../assets/images/иконка химчистка цвет 1.png"
import detailing from "../../assets/images/иконка детейлинг цвет 1.png"
import car from "../../assets/images/иконка кузов цвет 1.png"
import polishing from "../../assets/images/иконка полировка цвет 2.png"

type ServicesProps = {

}

function Services({

                }: ServicesProps
) {

  return (
    <div className={`${styles.content} ${styles.container} ${styles.services}`}>
      <h2>Наши услуги</h2>
      <div className={styles.opacityContainer}>
        <div className={`${styles.servicesList} ${styles.grid}`}>
          <div className={styles.service}>
            <ul>
              <li><img src={cleaning} alt=""/></li>
              <li><p>Химчистка салона</p></li>
            </ul>
          </div>
          <div className={styles.service}>
            <div>
              <ul>
                <li><img src={detailing} alt=""/></li>
                <li><p>Детейлинг</p></li>
              </ul>
            </div>
          </div>
          <div className={`${styles.service} ${styles.marginFix}`}>
            <div>
              <ul>
                <li><img src={car} alt=""/></li>
                <li><p>Кузовные работы</p></li>
              </ul>
            </div>
          </div>
          <div className={`${styles.service} ${styles.polishing}`}>
            <div>
              <ul>
                <li><img src={polishing} alt=""/></li>
                <li>
                  <div className={styles.serviceText}>
                    <p>Полировка кузова с нанесением керамики </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={`${styles.service} ${styles.polishing}`}>
            <div>
              <ul>
                <li><img src={polishing} alt=""/></li>
                <li>
                  <div className={styles.serviceText}>
                    <p>Полировка кузова с нанесением жидкого стекла</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
