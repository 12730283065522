import React from 'react';
import './App.css';
import Header from "./components/Header/Header";
import AboutService from "./components/AboutService/AboutService";
import Guarantees from "./components/Guarantees/Guarantees";
import Services from "./components/Services/Services";
import Works from "./components/Works/Works";
import Reviews from "./components/Reviews/Reviews";
import Footer from "./components/Footer/Footer";
import {
  BrowserRouter as Router,
} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header/>
        <AboutService/>
        <Guarantees/>
        <Services/>
        <Works/>
        <Reviews/>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
