import styles from "./Works.module.css"
import tabler from "../../assets/images/Tabler.png"
import tabler1 from "../../assets/images/Tabler-1.png"
import rectangle from "../../assets/images/Rectangle 14.png"

type WorksProps = {

}

function Works({

                }: WorksProps
) {

  return (
    <div className={`${styles.content} ${styles.container} ${styles.works}`}>
      <h2>Примеры наших работ</h2>
      <div className={styles.opacityContainer}>
        <div className={`${styles.grid} ${styles.flexContainer}`}>
          <h3>Марка машины</h3>
          <ul className={styles.grid}>
            <li><img src={tabler1} alt=""/></li>
            <li><img src={tabler} alt=""/></li>
          </ul>
        </div>
        <ul className={`${styles.worksList} ${styles.grid}`}>
          <li><img src={rectangle} alt=""/></li>
          <li><img src={rectangle} alt=""/></li>
          <li><img src={rectangle} alt=""/></li>
        </ul>
        <div className={`${styles.grid} ${styles.flexContainer}`}>
          <h3>Марка машины</h3>
          <ul className={styles.grid}>
            <li><img src={tabler1} alt=""/></li>
            <li><img src={tabler} alt=""/></li>
          </ul>
        </div>
        <ul className={`${styles.worksList} ${styles.grid}`}>
          <li><img src={rectangle} alt=""/></li>
          <li><img src={rectangle} alt=""/></li>
          <li><img src={rectangle} alt=""/></li>
        </ul>
      </div>
    </div>
  )
}

export default Works
