import styles from "./Guarantees.module.css"
import guaranteesImg from "../../assets/images/иконка главный 1.png"
import checkList from "../../assets/images/icon-checklist.png"

type GuaranteesProps = {

}

function Guarantees({

                }: GuaranteesProps
) {

  return (
    <div className={`${styles.content} ${styles.container} ${styles.guarantees}`}>
      <div className={`${styles.grid} ${styles.opacityContainer}`}>
        <img src={guaranteesImg} alt="guarantees img"/>
          <div>
            <h2>Мы гарантируем!</h2>
            <ul className={styles.guaranteesList}>
              <li>
                <div className={styles.grid}>
                  <img src={checkList} alt="check img"/>
                    <p>Качественный ремонт</p>
                </div>
              </li>
              <li>
                <div className={styles.grid}>
                  <img src={checkList} alt=""/>
                    <p>Соблюдение сроков ремонта</p>
                </div>
              </li>
              <li>
                <div className={styles.grid}>
                  <img src={checkList} alt=""/>
                    <p>Квалификацию и опыт мастеров</p>
                </div>
              </li>
              <li>
                <div className={styles.grid}>
                  <img src={checkList} alt=""/>
                    <p>Надёжное оборудование в автокомплексе</p>
                </div>
              </li>
              <li>
                <div className={styles.grid}>
                  <img src={checkList} alt=""/>
                    <p>Правильный подбор частей и материалов</p>
                </div>
              </li>
            </ul>
          </div>
      </div>
    </div>
  )
}

export default Guarantees
