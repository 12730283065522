import styles from "./Header.module.css"
import phone from "../../assets/images/icon-tel.png"
import logo from "../../assets/images/logo.svg"

type HeaderProps = {
    // web3Provider: StateType["web3Provider"],
    // disconnect: Function,
    // connectWallet: Function
    // setBalanceModal: Function,
}

function Header({
                    // web3Provider,
                    // disconnect,
                    // connectWallet,
                    // setBalanceModal,
                }: HeaderProps
) {

  return (
    <div className={styles.bar}>
      <header className={`${styles.grid} ${styles.content}`}>
        <div className={styles.logo}>
          <img src={logo} alt="logo"/>
        </div>
        <nav>
          <ul className={styles.grid}>
            <div> <li className={styles.navItem}><a href="#">Главная</a></li></div>
            <div><li className={styles.navItem}><a href="#">Галерея</a></li></div>
            <div><li className={styles.navItem}><a href="#">Отзывы</a></li></div>
            {/*<li className={styles.navItem}><a href="#">Главная</a></li>*/}
            {/*<li className={styles.navItem}><a href="#">Галерея</a></li>*/}
            {/*<li className={styles.navItem}><a href="#">Отзывы</a></li>*/}
          </ul>
        </nav>
        <div className={`${styles.phoneNumber} ${styles.grid}`}>
          <img src={phone} alt="phone"/>
            <p>+7(904)723-65-35</p>
        </div>
      </header>
    </div>
  )
}

export default Header
