import styles from "./Reviews.module.css"
import line1 from "../../assets/images/Line_1.png"
import button1 from "../../assets/images/кнопка1.png"
import button2 from "../../assets/images/кнопк2.png"

type ReviewsProps = {

}

function Reviews({

                }: ReviewsProps
) {

  return (
    <div className={`${styles.content} ${styles.container} ${styles.reviews} ${styles.opacityContainer}`}>
      <h2>Отзывы</h2>
      <h3>Что говорят наши клиенты</h3>
      <div>
        <ul className={`${styles.reviewsList} ${styles.grid}`}>
          <li><img src={button1} alt=""/></li>
          <li className={styles.review}>
            <div>
              <h4>Татьяна</h4>
              <p className={styles.textReview}>
                Повседневная практика показывает, что дальнейшее развитие различных форм деятельности однозначно
                определяет каждого участника как способного принимать собственные решения касаемо своевременного
                выполнения сверхзадачи. Также как внедрение современных методик позволяет оценить значение системы
                обучения кадров, соответствующей насущным потребностям.
              </p>
              <div className={styles.image}>
                <img src={line1} alt="test"/>
              </div>
              <p className={`${styles.textReview} ${styles.bigSize}`}>
                7 октября 2022
              </p>
            </div>
          </li>
          <li className={styles.review}>
            <div>
              <h4>Татьяна</h4>
              <p className={styles.textReview}>
                Повседневная практика показывает, что дальнейшее развитие различных форм деятельности однозначно
                определяет каждого участника как способного принимать собственные решения касаемо своевременного
                выполнения сверхзадачи. Также как внедрение современных методик позволяет оценить значение системы
                обучения кадров, соответствующей насущным потребностям.
              </p>
              <div className={styles.image}>
                <img src={line1} alt="test"/>
              </div>
              <p className={`${styles.textReview} ${styles.bigSize}`}>
                7 октября 2022
              </p>
            </div>
          </li>
          <li className={`${styles.review} ${styles.lastReview}`}>
            <div>
              <h4>Татьяна</h4>
              <p className={styles.textReview}>
                Повседневная практика показывает, что дальнейшее развитие различных форм деятельности однозначно
                определяет каждого участника как способного принимать собственные решения касаемо своевременного
                выполнения сверхзадачи. Также как внедрение современных методик позволяет оценить значение системы
                обучения кадров, соответствующей насущным потребностям.
              </p>
              <div className={styles.image}>
                <img src={line1} alt="test"/>
              </div>
              <p className={`${styles.textReview} ${styles.bigSize}`}>
                7 октября 2022
              </p>
            </div>
          </li>
          <li><img src={button2} alt=""/></li>
        </ul>
      </div>
    </div>
  )
}

export default Reviews
