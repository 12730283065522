import styles from "./Footer.module.css"
import phone from "../../assets/images/icon-tel.png"
import map from "../../assets/images/icon-map-pin.png"
import sampleImage from "../../assets/images/SampleImage.png"

type FooterProps = {

}

function Footer({

                }: FooterProps
) {

  return (
    <div className={`${styles.bar} ${styles.footer} ${styles.container}`}>
      <footer className={`${styles.grid} ${styles.content}`}>
        <ul className={styles.listContacts}>
          <li className={styles.contact}>Контакты</li>
          <li>
            <div className={styles.grid}>
              <img src={phone} alt=""/>
                <p>+7(904)723-65-35</p>
            </div>
          </li>
          <li>
            <div className={styles.grid}>
              <img src={map} alt=""/>
                <p>г. Владивосток, ул. Политаева, д2а</p>
            </div>
          </li>
          <li>
            <div className={styles.grid}>
              <img src={map} alt=""/>
                <p>г. Владивосток, ул. Политаева, д2а</p>
            </div>
          </li>
        </ul>
        <img className={styles.map} src={sampleImage} alt=""/>
      </footer>
    </div>
  )
}

export default Footer
