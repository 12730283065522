import styles from "./AboutService.module.css"
import services from "../../assets/images/иконки услуги 2 5.png"

type AboutServiceProps = {

}

function AboutService({

                }: AboutServiceProps
) {

  return (
    <div className={`${styles.content} ${styles.container} ${styles.aboutService}`}>
      <div className={`${styles.grid} ${styles.opacityContainer}`}>
        <div>
          <h2>О нашем сервисе</h2>
          <p className={styles.text}>
            Автокомплекс Карс-Авто — это команда профессионалов, которая позаботится о вашем автомобиле. Мы специализируемся на ремонте, диагностике и техобслуживании любых марок.
            <br/>
            <br/>
            Наш автокомплекс на рынке с 2013 года. Мы дорожим каждым нашим клиентом, поэтому высокое качество сервиса - приоритет для нас.
            <br/>
            <br/>
            Ремонт автомобиля- дело непростое и лучше его доверить профессионалам. Вы можете доверить его нам, а мы в свою очередь сбережем ваши силы, время и деньги. За время существования нашего автосервиса мы накопили богатейший опыт по ремонту машин японских и европейских брендов. Мы предлагаем услуги от профессионалов по доступным ценам.
          </p>
        </div>
        <img className={styles.aboutServiceImg} src={services} alt=""/>
      </div>
    </div>
  )
}

export default AboutService
